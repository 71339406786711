<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col cols="4">
        <div class="d-flex">
          <div class="sliders-array-holder">
            <div>
              <h1 class="ml-2 mt-2" style="font-weight: 200">
                Simulador {{ processedSingleCampaign.processedName }}
              </h1>
            </div>
            <div class="d-flex">
              <v-btn
                x-small
                class="grey--text ml-2"
                elevation="0"
                @click="() => this.$router.push({ path: '/abstract-campaign' })"
                >Todas las Campañas</v-btn
              >
              <v-btn
                x-small
                class="grey--text ml-2"
                elevation="0"
                @click="
                  () =>
                    this.$router.push({
                      path: '/abstract-campaign/' + this.$route.params.id1,
                    })
                "
                >Ir a versiones de Campañas</v-btn
              >
            </div>
            <br />

            <div class="ml-2 slider-holder">
              <span class="spans-5ch grey--text">TODO</span>
              <v-slider
                thumb-label
                min="20"
                max="200"
                step="10"
                show-ticks="always"
                v-model="menem"
                @change="pass"
              ></v-slider>
              <span class="grey--text slider-tip">{{ menem }}% </span>
            </div>
            <div
              class="ml-2 slider-holder"
              v-for="(regionModifierGroup, i) in costItemModifiers"
              :key="regionModifierGroup.regionId"
            >
              <span class="spans-5ch grey--text">{{
                regions.find((el) => el.id == regionModifierGroup.regionId)
                  .abbreviation
              }}</span>
              <v-slider
                thumb-label
                min="20"
                max="200"
                step="10"
                show-ticks="always"
                v-model="costItemModifiers[i].average"
                @change="reactToSliderChange(i)"
              ></v-slider>
              <span class="grey--text slider-tip"
                >{{ costItemModifiers[i].average }}%
              </span>
            </div>
          </div>

          <DenseCashflowTable
            :rows="engagementCashflowRows"
            :totalHaToolTip="campaignTotalHaToolTip"
            :columns="engagementCashflowColumns"
            :isResourceUtilization="false"
            :startDate="campaignStartDate"
            :endDate="campaignEndDate"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import { unPackAndProcessCashflowKeys } from "../utils";
import { getRequest, putRequest } from "../managrx/serverCall";
import { campaignLevelTotalHaToolTip } from "../managrx/constants.js";

// const REGION_MAP = {
//   1: { id: 1, name: "Oeste Norte", abbreviation: "ONO", created_by: 1 },
//   2: { id: 2, name: "NorOeste Argentino", abbreviation: "NOA", created_by: 1 },
//   3: { id: 3, name: "Norte Buenos Aires", abbreviation: "NBA", created_by: 1 },
//   4: {
//     id: 4,
//     name: "SurEste Buenos Aires",
//     abbreviation: "SBA",
//     created_by: 1,
//   },
//   5: { id: 5, name: "Sur Santa Fe", abbreviation: "SSF", created_by: 1 },
//   6: {
//     id: 6,
//     name: "SurOeste Entre Rios",
//     abbreviation: "SOER",
//     created_by: 1,
//   },
//   7: { id: 7, name: "NorEste Argentino", abbreviation: "NEA", created_by: 1 },
//   8: { id: 8, name: "Norte Santa Fe", abbreviation: "NSF", created_by: 1 },
//   9: { id: 9, name: "Cordoba", abbreviation: "COR", created_by: 1 },
//   10: { id: 10, name: "Ctro Sta FE", abbreviation: "CSF", created_by: 1 },
//   11: { id: 11, name: "Oeste Sur", abbreviation: "OSU", created_by: 1 },
// };
export default {
  name: "SingleCampaignSimulation",
  data() {
    return {
      availableRegions: [],
      selectedRegions: [],
      campaignMetrics: {},
      makeActivityGroup: false,
      search: null,
      changeUnitsButtonTagT1: "USD",
      isUsdTotalMetricT1: false,
      metricUnitsT1: "USD/ha",
      changeUnitsButtonTagT3: "USD",
      isUsdTotalMetricT3: false,
      metricUnitsT3: "USD/ha",
      showCampaignDetail: false,
      showMetrics: false,
      showPrices: false,
      showCashflows: false,
      showEngagements: true,
      showActions: false,
      showNewEngagement: false,
      menem: 40,
      // Simulations
      slides_data: [
        50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
        50, 50,
      ],
      costItemModifiers: [],
    };
  },
  methods: {
    pass() {
      console.log("WIP");
    },

    async reactToSliderChange(index) {
      this.$store.dispatch("setPageLoadingStatus", true);

      let mGroup = this.costItemModifiers[index];
      let modifierValue = mGroup.average / 100;
      let modifiers = mGroup["modifiers"];

      console.log(modifierValue);
      console.log(modifiers);
      let payload = {
        bulkUpdate: modifiers.map((el) => {
          return { id: el.id, modifier: modifierValue };
        }),
      };

      const response = await putRequest(
        "/module/put/simulation/cost_item_family" + `/${this.campaignId}`,
        payload
      );
      console.log(response);

      await this.bringAndProcessModifiers();

      await this.$store.dispatch("getCampaignSimulationCashflow", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
      });
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async bringAndProcessModifiers() {
      let modifiers = await getRequest(
        `/simulation_modifier_cost_item_family?q=campaign_id:${this.campaignId}limit=2500`,
        false
      );

      let rawModifiers = modifiers?.data
      let modifierIdsMap = {};
      let modifiersArray = [];
      for (let i = 0; i < this.regions.length; i++) {
        const regionId = this.regions[i].id;

        let processedMods = rawModifiers
          .filter((mod) => mod.regionId == regionId)
          .map((mod) => {
            return {
              id: mod.id,
              modifier: mod.modifier,
              speciesId: mod.speciesId,
            };
          });
        let modObject = {
          regionId: regionId,
          average: Math.round(
            100 *
              processedMods
                .map((mod) => mod.modifier)
                .reduce((p, c) => p + c / processedMods.length, 0)
          ),
          modifiers: processedMods,
        };
        modifierIdsMap[regionId] = modObject;
        modifiersArray.push(modObject);
      }
      this.costItemModifiers = modifiersArray;
    },
  },
  computed: {
    ...mapGetters([
      "singleCampaign",
      "singleAbstractCampaign",
      "regions",
      "species",
      "campaignActivities",
      "campaignEngagements",
      "establishments",
      "activityPricesFromCampaign",
      "isPageLoading",
      "campaignCashflow",
      "campaignResourceUtilization",
    ]),
    selectableCampaignEngagementsValues() {
      return this.campaignEngagementsValues.filter((el) =>
        this.selectedRegions.includes(el.region)
      );
    },
    campaignTotalHaToolTip() {
      return campaignLevelTotalHaToolTip;
    },
    campaignStartDate() {
      return this.singleAbstractCampaign?.startDate;
    },
    campaignEndDate() {
      return this.singleAbstractCampaign?.endDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.campaignEndDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.campaignStartDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.campaignEndDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.campaignStartDate);

      return date.getFullYear();
    },
    // wip
    isCampaignActivitiesEmpty() {
      return this.campaignActivities.length === 0;
    },
    engagementCashflowColumns() {
      return unPackAndProcessCashflowKeys(this.campaignCashflow?.keys);
    },
    engagementCashflowRows() {
      return this.campaignCashflow?.rows;
    },
    engagementResourceUtilizationColumns() {
      return unPackAndProcessCashflowKeys(
        this.campaignResourceUtilization?.keys
      );
    },
    engagementResourceUtilizationRows() {
      return this.campaignResourceUtilization?.rows;
    },
    campaignId() {
      return parseInt(this.$route.params.id2);
    },
    processedSingleCampaign() {
      return {
        id: this.singleCampaign.id,
        version: this.singleCampaign.version,
        name: this.singleAbstractCampaign.name,
        processedName:
          this.singleAbstractCampaign.name + " v" + this.singleCampaign.version,
        endDate: this.singleAbstractCampaign.endDate?.slice(0, 10),
        startDate: this.singleAbstractCampaign.startDate?.slice(0, 10),
      };
    },
    campaignEngagementsValues() {
      if (this.campaignEngagements?.items.length) {
        if (this.search) {
          let search = this.search.toLowerCase();
          return this.campaignEngagements?.items.filter((el) =>
            el.establishmentName.toLowerCase().includes(search)
          );
        }
        return this.campaignEngagements?.items;
      } else {
        return [];
      }
    },
    establishmentName() {
      return this.singleEstablishment?.name;
    },
  },
  async mounted() {
    this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
    this.$store.dispatch("getCampaignEngagements", this.$route.params.id2);
    this.$store.dispatch("getAbstractCampaigns");
    this.$store.dispatch("getCampaigns");
    this.$store.dispatch("getEstablishments");
    this.$store.dispatch(
      "getActivityPricesFromCampaign",
      this.$route.params.id2
    );

    await Promise.all([
      this.$store.dispatch("getSingleAbstractCampaign", this.$route.params.id1),
      this.$store.dispatch("getCampaignActivities", {
        filterKey: "campaign_id",
        filterValue: this.$route.params.id2,
      }),
    ]);
    document.title = this.processedSingleCampaign?.processedName;
    if (!this.isCampaignActivitiesEmpty) {
      this.$store.dispatch("getCampaignSimulationCashflow", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
      });
      let response = await getRequest(
        `/module/get/metrics/campaign/${this.$route.params.id2}?start_year=${this.cashflowStartYear}&start_month=${this.cashflowStartMonth}&end_year=${this.cashflowEndYear}&end_month=${this.cashflowEndMonth}&use_gather=1`,
        true
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.campaignMetrics = response?.data;
        this.campaign;
      }
    }
    for (const campaignEngagement of this.campaignEngagementsValues) {
      let region = campaignEngagement.region;
      if (!this.availableRegions.includes(region)) {
        this.availableRegions.push(region);
      }
    }
    this.selectedRegions = this.availableRegions;

    this.bringAndProcessModifiers();
  },
  components: {
    DenseCashflowTable,
  },
};
</script>

<style>
.slider-holder {
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.spans-5ch {
  width: 5ch;
}

.sliders-array-holder {
  min-width: 500px;
}

.slider-tip {
  min-width: 4ch;
}
</style>
